.editorWrapper {
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: -0.5rem;
}

.contentEditable {
  outline: 0;
  font-size: var(--nextui-font-size-small);
  padding-bottom: 0.5rem;
}

.placeholder {
  position: absolute;
  top: 1px;
  left: 0;
  pointer-events: none;
  font-size: var(--nextui-font-size-small);
  color: hsl(var(--nextui-foreground-400));
}

.widgets {
  display: flex;
}
